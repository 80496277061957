import SiteButton_IronButtonComponent from '../components/SiteButton/viewer/skinComps/IronButton/IronButton.skin';
import SiteButton_IronButtonController from '../components/SiteButton/viewer/SiteButton.controller';


const SiteButton_IronButton = {
  component: SiteButton_IronButtonComponent,
  controller: SiteButton_IronButtonController
};


export const components = {
  ['SiteButton_IronButton']: SiteButton_IronButton
};

